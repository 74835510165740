import {Card, Descriptions, Space} from "antd";
import React, {memo} from "react";
import AccompanyingDocumentPdf from "./AccompanyingDocumentPdf";

export type CreateXmlModalPreviewProps = {
    documentInfo: Array<{label: string; value: string | number}>;
    creditorInfo: Array<{label: string; value: string}>;
    transactionInfo: Array<Array<{label: string; value: string | number}>>;
    panelWidth: number;
    cardWidth: number;
};

const CreateXmlModalPreview = (props: CreateXmlModalPreviewProps) => {
    const {documentInfo, creditorInfo, transactionInfo, panelWidth, cardWidth} = props;

    const DescriptionSegment = ({
        title,
        content,
        extra,
    }: {
        title?: string;
        content: Array<{label: string; value: string}>;
        extra?: React.ReactNode;
    }) => {
        return (
            <Card
                title={title}
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
                extra={extra}
            >
                <Descriptions bordered={true} size="small" column={6} style={{width: "100%"}}>
                    {content.map((entry, index) => {
                        return (
                            <Descriptions.Item
                                span={6}
                                key={`${entry.label}`}
                                label={entry.label}
                                // labelStyle={{width: panelWidth / 4}}
                                labelStyle={{width: "40%"}}
                                contentStyle={{width: "60%"}}
                            >
                                <div>{entry.value}</div>
                            </Descriptions.Item>
                        );
                    })}
                </Descriptions>
            </Card>
        );
    };

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            <DescriptionSegment
                key="documentInfo"
                title="Dokument Informationen"
                content={documentInfo}
                extra={<AccompanyingDocumentPdf fileName="Dateiname" props={props} />}
                style={{width: "100%"}}
            />
            <DescriptionSegment key="creditorInfo" title="Kreditor" content={creditorInfo} />
            <Space direction="vertical" style={{width: "100%"}}>
                <h4>{"Transaktionen"}</h4>
                {transactionInfo.map((transaction, index) => {
                    const mandateId = transaction.filter((entry) => entry.label === "Mandatsreferenz")[0].value;

                    const key = `${mandateId}-${index}`;

                    return <DescriptionSegment key={key} content={transaction} />;
                })}
            </Space>
        </Space>
    );
};

export default memo(CreateXmlModalPreview);
