import React, {FC, memo, ReactElement, ReactNode, useState} from "react";
import {ApolloError} from "apollo-client";
import {Button} from "antd";
import {useHistory} from "react-router";
import classnames from "classnames";
import css from "./LayoutStep.less";
import {isDefined} from "../../helpers/typeScriptHelpers";
import i18n from "../../services/i18n";
import AbortCard from "../AbortCard/AbortCard";
import LessonBookingErrors from "../../containers/bookings/lessonBooking/CreateLessonBooking/LessonBookingErrors";

type LayoutStepProps = {
    children?: React.ReactNode;
    icon?: React.ReactNode;
    title?: string | React.ReactNode;
    subtitle?: string | React.ReactNode | null;
    error?: Error | ApolloError;
    button?: ReactElement;
    cancel?: boolean;
    toDelete?: ReactElement;
    toSave?: ReactElement;
    toNext?: ReactElement;
    columns?: number;
    style?: React.CSSProperties;
    marginBottomMultiplier?: number;
    className?: string;
    bodyClassName?: string;
};

type LayoutFormCSSProperties = React.CSSProperties & {
    "--column-count": number;
    "--margin-bottom": number;
};

const LayoutStep: FC<LayoutStepProps> = ({
    icon,
    title = "",
    subtitle = "",
    button = null,
    cancel = true,
    toDelete = null,
    toSave = null,
    toNext = null,
    children = null,
    columns = 6,
    style,
    error,
    marginBottomMultiplier = 1,
    className,
    bodyClassName,
    ...props
}) => {
    const actualStyle: LayoutFormCSSProperties = {
        "--column-count": columns,
        "--margin-bottom": marginBottomMultiplier,
        ...style,
    };
    const [abortOpened, setAbortOpened] = useState<boolean>(false);

    const history = useHistory();

    const onAbort = (): void => {
        setAbortOpened(false);
        history.goBack();
    };

    const actualClassName = classnames(css.root, className);

    return (
        <section id="LayoutStep" className={actualClassName} style={actualStyle} {...props}>
            <div className={css.scrollable}>
                {isDefined(title) && (
                    <>
                        <header className={css.header}>
                            <hgroup className={css.title}>
                                {icon && <span className={css.icon}>{icon}</span>}
                                <h3 className={css.mainTitle}>{title}</h3>
                                <h2 className={css.subTitle}>{subtitle}</h2>
                            </hgroup>
                            {/* {error !== undefined && (
                                <Alert message={error.name} description={error.message} type="error" closable />
                            )} */}
                            {cancel === false && button === null ? null : (
                                <div className={classnames(css.sectionMenu, css.buttonMenu)}>
                                    {cancel ? (
                                        <Button type="danger" ghost onClick={() => setAbortOpened(true)}>
                                            {i18n.views.AbortCard.cancel()}
                                        </Button>
                                    ) : null}

                                    {button === null ? null : <span>{button}</span>}
                                    {toDelete === null && toSave === null && toNext === null ? null : (
                                        <div>
                                            {toDelete === null ? null : toDelete}
                                            {toSave === null ? null : toSave}
                                            {toNext === null ? null : toNext}
                                        </div>
                                    )}
                                </div>
                            )}
                        </header>
                        <div className={css.errorContainer}>
                            {error !== undefined && <LessonBookingErrors error={error} />}
                        </div>
                    </>
                )}
                <div
                    className={classnames(css.inner, bodyClassName)}
                    style={{
                        padding: actualStyle.padding,
                        height: actualStyle.height,
                    }}
                >
                    {children}
                </div>
                <AbortCard
                    title={i18n.views.AbortCard.title()}
                    visible={abortOpened}
                    handleOk={onAbort}
                    handleCancel={() => {
                        setAbortOpened(false);
                    }}
                />
            </div>
        </section>
    );
};

export default memo(LayoutStep);
