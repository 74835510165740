import React, {FC, memo} from "react";
import {Space} from "antd";
import classNames from "classnames";
import {red} from "@ant-design/colors";
import {
    GetCourseDetailsByIdQuery,
    PaymentInterval,
    GetBookingByIdWithCourseLessonBookingsQuery,
} from "../../../../generated/graphql";
import PanelCard from "../../../../views/PanelCard/PanelCard";
import i18n from "../../../../services/i18n";
import css from "./CourseDetailsPanels.less";
import {
    RenderAmount,
    renderFirstCourseLesson,
    renderInstructors,
    RenderPricePer,
} from "../../../../views/TableColumnRenderers/TableColumnRenderers";

type PossibleCourseInput =
    | GetCourseDetailsByIdQuery["courseDetailsById"]["course"]
    | GetBookingByIdWithCourseLessonBookingsQuery["bookingByIdWithCourseLessonBookings"]["courseLessons"][0]["course"];

type CourseDetailsOverviewProps = {
    course: PossibleCourseInput;
    extra?: React.ReactNode;
    gapAfter?: boolean;
    lessonCount: number;
};

const CourseDetailsOverview: FC<CourseDetailsOverviewProps> = ({course, extra, gapAfter, lessonCount}) => {
    // return <div>{"CourseOverview"}</div>;

    const cardTitle = (
        <Space>
            <div>{i18n.containers.courses.CourseDetails.courseData()}</div>
            <div style={{opacity: 0.33}}>{course.prefixedCourseNumber}</div>
        </Space>
    );

    const AdditionalShortInfo = () => {
        const additionalShortInfo = course.additionalShortInfo;

        if (additionalShortInfo && additionalShortInfo.length > 0) {
            return (
                <Space className={css.additionalShortInfo} size={3}>
                    <label>{`${i18n.containers.courses.CourseCreate.additionalShortInfo.labelShort()}:`}</label>
                    <div>{additionalShortInfo}</div>
                </Space>
            );
        }

        return null;
    };

    type ContactCardCSSProperties = React.CSSProperties & {
        "--column-count": number;
    };

    const actualStyle: ContactCardCSSProperties = {
        "--column-count": 1,
        // ...style,
    };

    return (
        <div>
            <PanelCard
                columns={4}
                title={cardTitle}
                extra={
                    <>
                        <AdditionalShortInfo />
                        {extra}
                    </>
                }
                gapAfter={gapAfter}
            >
                <div className={classNames(css.withDivider, css.container)} style={actualStyle}>
                    <div className={css.label}>{i18n.containers.courses.CourseDetails.location()}</div>
                    <div className={css.entry}>
                        {course.location.city}
                        <br />
                        {course.location.name}
                    </div>
                    <div className={css.label}>{i18n.containers.courses.CourseDetails.coursePrice()}</div>
                    <div className={css.entry}>
                        <span>
                            <RenderPricePer
                                feeAmount={course.coursePrice[0].grossPrice}
                                paymentInterval={course.paymentInterval}
                            />
                        </span>
                        {course.paymentInterval === PaymentInterval.PerLesson && (
                            <div
                                style={{
                                    display: "flex",
                                    fontWeight: 650,
                                    fontSize: "0.8em",
                                }}
                            >
                                {"("}
                                <RenderAmount
                                    // amount={course.coursePrice[0].grossPrice * course.lessons.length}
                                    amount={course.coursePrice[0].grossPrice * lessonCount}
                                    style={{
                                        fontWeight: 650,
                                        marginRight: 2,
                                        marginLeft: 1,
                                    }}
                                />
                                {"gesamt)"}
                            </div>
                        )}
                    </div>
                </div>
                <div className={classNames(css.withDivider, css.container)} style={actualStyle}>
                    <div className={css.label}>{i18n.containers.courses.CourseDetails.firstCourseLesson()}</div>
                    <div className={css.entry}>
                        {renderFirstCourseLesson({
                            startEnd: {
                                startDateTime: course.startDateTime,
                                endDateTime: course.endDateTimeFirstCourseLesson,
                            },
                        })}
                    </div>
                    <div className={css.label}>{i18n.containers.courses.CourseDetails.instructors()}</div>
                    <div className={css.entry}>{renderInstructors(course.instructors)}</div>
                </div>
                <div className={classNames(css.withDivider, css.container)} style={actualStyle}>
                    <div className={css.labelSmall}>{i18n.containers.courses.CourseDetails.waitListCount()}</div>
                    <div className={css.entryLarge}>{course.waitListCount}</div>
                    <div className={css.labelSmall}>{i18n.containers.courses.CourseDetails.reservationCount()}</div>
                    <div className={css.entryLarge}>{course.placeReservationsCount}</div>
                    <div className={css.labelSmall}>{i18n.containers.courses.CourseDetails.bookingCount()}</div>
                    <div className={css.entryLarge}>{course.bookingCount}</div>
                </div>
                <div className={classNames(css.container)} style={actualStyle}>
                    <div className={css.labelSmall}>{i18n.containers.courses.CourseDetails.maxAttendees()}</div>
                    <div className={css.entryLarge}>{course.maxAttendees}</div>
                    <div className={css.labelSmall}>{i18n.containers.courses.CourseDetails.freePlaces()}</div>
                    <div
                        className={css.entryLarge}
                        style={{
                            fontSize: "4em",
                            color: course.freePlaces >= 0 ? undefined : red[4],
                        }}
                    >
                        {course.freePlaces}
                    </div>
                </div>
            </PanelCard>
        </div>
    );
};

export default memo(CourseDetailsOverview);
