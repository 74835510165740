import React, {FC, memo, useState, useMemo} from "react";
import {Checkbox, Space, Table, Tooltip} from "antd";
import {ColumnProps, TablePaginationConfig} from "antd/lib/table";
import moment, {Moment} from "moment";
import classNames from "classnames";
import {
    BookingType,
    GetBookingByIdWithCourseLessonBookingsQuery,
    GetHolidaysByCompanyAndDateRangeQuery,
    useGetHolidaysByCompanyAndDateRangeQuery,
} from "../../generated/graphql";
import {RowIndex} from "../../containers/courses/CourseViews/CourseLessonViews";
import i18n from "../../services/i18n";
import {
    renderDateAsWeekdayTag,
    renderDateFormatted,
    renderSlotsInLesson,
} from "../TableColumnRenderers/TableColumnRenderers";
import {sortByStartDateTime} from "../../helpers/sortingFunctions";
import css from "./LessonBookingCourseLessonTableSettled.less";
import PanelCard from "../PanelCard/PanelCard";

// Typen
type LessonBookingCourseLessonTableSettledProps = {
    booking: GetBookingByIdWithCourseLessonBookingsQuery["bookingByIdWithCourseLessonBookings"];
    className?: string;
    setShownLessonCount: (count: number) => void;
};
// type LessonBookingCourseLessonTableSettledData = GetCourseInfosForBookingQuery["getCourseInfosForBooking"]["lessons"][number];
type LessonBookingCourseLessonTableSettledData = GetBookingByIdWithCourseLessonBookingsQuery["bookingByIdWithCourseLessonBookings"]["courseLessons"][number];

const defaultPageSize = 10;

// Hauptkomponente
const LessonBookingCourseLessonTableSettled: FC<LessonBookingCourseLessonTableSettledProps> = ({
    booking,
    className,
    setShownLessonCount,
}) => {
    const course = booking.courseLessons[0].course;
    const courseLessonBookings = booking.courseLessonBookings ?? [];
    const bookedLessons = courseLessonBookings.filter((lesson) => lesson.bookingType === BookingType.Booking);
    const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: defaultPageSize,
        size: "small",
        pageSizeOptions: [defaultPageSize, 20, 50, 100],
        // hideOnSinglePage: true,
        // showTotal: (total, range) => `${range[0]}-${range[1]} von ${total}`,
        // position: ["bottomCenter"],
        // style: {display: "flex"},
    });

    // Holidays Query - optimiert mit cache-first, um unnötige Anfragen zu vermeiden
    const holidayQueryVariables = useMemo(
        () => ({
            start: course.startDateTime,
            end: course.endDateTime,
        }),
        [course.startDateTime, course.endDateTime],
    );

    const {data: holidayData, loading: loadingHolidays} = useGetHolidaysByCompanyAndDateRangeQuery({
        variables: holidayQueryVariables,
        fetchPolicy: "cache-first",
        // Hinzufügen einer eindeutigen Cache-ID, um sicherzustellen, dass Abfragen mit den gleichen Datumsangaben aus dem Cache bedient werden
        context: {
            clientName: "default",
            cacheKey: `holidays_${course.id}_${course.startDateTime}_${course.endDateTime}`,
        },
    });
    const holidays = holidayData?.holidaysByCompanyAndDateRange ?? [];

    // Helper Funktionen
    const getHolidayInfo = (date: Moment) => {
        const allHolidaysOfDate = holidays.filter((holiday) => {
            const holidayStart = moment(holiday.start).startOf("day");
            const holidayEnd = moment(holiday.end).endOf("day");

            return moment(date).isSameOrAfter(holidayStart) && moment(date).isSameOrBefore(holidayEnd);
        });

        return allHolidaysOfDate.find((holiday) => holiday.stateCode === "CUSTOM") ?? allHolidaysOfDate[0];
    };

    const renderIndex = (
        rowIndex: number,
        holiday?: GetHolidaysByCompanyAndDateRangeQuery["holidaysByCompanyAndDateRange"][number],
    ) => {
        return <RowIndex rowIndex={rowIndex} holiday={holiday} />;
    };

    const columns: Array<ColumnProps<LessonBookingCourseLessonTableSettledData>> = [
        {
            title: "#",
            dataIndex: "number",
            align: "center",
            render: (text, record, rowIndex) =>
                renderIndex(record?.indexOfLessonInCourse ?? rowIndex, getHolidayInfo(record.startDateTime)),
        },
        {
            title: i18n.containers.courses.CourseCreate.courseStartDateTime.weekday(),
            dataIndex: "dayOfTheWeek",
            render: (text, record) =>
                renderDateAsWeekdayTag({
                    value: record.startDateTime,
                    style: {
                        width: "100%",
                        textAlign: "center",
                        fontWeight: 600,
                    },
                }),
        },
        {
            title: i18n.containers.courses.CourseCreate.courseStartDateTime.date(),
            dataIndex: "startDateTime",
            render: (text, record) => renderDateFormatted(record.startDateTime, "DD. MMM YYYY"),
            sorter: sortByStartDateTime,
            sortDirections: [],
            sortOrder: "ascend",
        },
        {
            title: i18n.containers.courses.CourseCreate.slotsInLesson.label(),
            dataIndex: "maxAttendees",
            align: "right",
            render: (text, record) => ({
                displayName: "Slots",
                children: (
                    <Tooltip title={i18n.containers.courses.CourseCreate.slotsInLesson.description()}>
                        <div className={css.slotsInLesson}>
                            {renderSlotsInLesson({
                                maxAttendees: course.maxAttendees,
                                courseLessonBookingCount: record.courseLessonBookingCount,
                            })}
                        </div>
                    </Tooltip>
                ),
            }),
        },
    ];

    const renderTable = () => {
        return (
            <Table<LessonBookingCourseLessonTableSettledData>
                rowSelection={{
                    type: "checkbox",
                    selectedRowKeys: bookedLessons.map((lesson) => lesson.courseLessonId),
                    hideSelectAll: true,
                    renderCell: (_, record) => ({
                        children: (
                            <Checkbox
                                disabled
                                checked={bookedLessons.some((lesson) => lesson.courseLessonId === record.id)}
                            />
                        ),
                    }),
                }}
                rowClassName={(record) => {
                    if (bookedLessons.some((lesson) => lesson.courseLessonId === record.id)) {
                        return classNames(css.extraSmallRow, css.bookedLesson);
                    }

                    return classNames(css.extraSmallRow, css.unbookedLesson);
                }}
                loading={loadingHolidays}
                size="small"
                rowKey="id"
                columns={columns}
                dataSource={booking.courseLessons}
                pagination={paginationConfig}
                onChange={(pagination) => {
                    setPaginationConfig(pagination);

                    if (pagination.pageSize) {
                        if (pagination.pageSize < booking.courseLessons.length) {
                            setShownLessonCount(pagination.pageSize);
                        } else {
                            setShownLessonCount(booking.courseLessons.length);
                        }
                    }
                }}
            />
        );
    };

    return (
        <PanelCard
            columns={1}
            title={`${
                booking.courseLessons.length
            } ${i18n.containers.bookings.BookingSummary.sectionHeaders.courseLessons()}`}
            gapAfter
            className={className}
            style={{
                height: `calc(100% - 10px)`,
            }}
        >
            {renderTable()}
        </PanelCard>
    );
};

export default memo(LessonBookingCourseLessonTableSettled);
