import {Button, Space, message} from "antd";
import moment, {Moment} from "moment";
import React, {FC, memo, useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {
    GetBookingByIdDocument,
    GetBookingByIdWithCourseLessonBookingsDocument,
    GetPaidScheduledPaymentsDocument,
    GetUnpaidScheduledPaymentsDocument,
    GetUnpaidScheduledPaymentsQueryVariables,
    TablesEnum,
    useGetUnpaidScheduledPaymentsQuery,
    useUpdateManyScheduledPaymentIdsMutation,
} from "../../generated/graphql";
import i18n from "../../services/i18n";
import CalendarPopover from "../../views/CalendarPopover/CalendarPopover";
import Page from "../../views/Page/Page";
import CreateXmlModal from "./CreateXmlModal/CreateXmlModal";
import PaymentsList from "./PaymentsList";
import {TableType} from "./PaymentsListColumns";
import {useLocalStorageOptions} from "../../helpers/columnsLocalStorageHandler";
import UploadSwissQrCamtModal from "../swissQR/UploadSwissQrCamtFile/UploadSwissQrCamtModal";
import Submenu from "./Submenu";

type OpenPaymentType = {
    hideTitle?: boolean;
    customColumns?: Array<Extract<keyof TableType, string>>;
    hidePagination?: boolean;
    tablesEnum?: TablesEnum;
    hideXmlBtn?: boolean;
    style?: React.CSSProperties;
    bookingId?: string;
    hiddenFilters?: Array<Extract<keyof TableType, string>>;
    minPadding?: boolean;
    pollInterval?: number;
    tableTitle?: string | React.ReactNode;
    hideSpecialFunctions?: boolean;
    hideFooter?: boolean;
    mandatoryColumns?: Array<Extract<keyof TableType, string>>;
    timeStamp?: number;
    setTimeStamp?: (newTimeStamp: number) => void;
};

const OpenPaymentsList: FC<OpenPaymentType> = ({
    hideTitle,
    customColumns,
    hidePagination,
    tablesEnum = TablesEnum.OpenPaymentsList,
    hideXmlBtn,
    style,
    bookingId,
    hiddenFilters,
    minPadding,
    pollInterval,
    tableTitle,
    hideSpecialFunctions,
    hideFooter = false,
    mandatoryColumns,
    timeStamp,
    setTimeStamp,
}) => {
    const localStorageName = tablesEnum;
    const [totalResults, setTotalResults] = useState<number>(0);
    const [options, setOptions] = useLocalStorageOptions({
        localStorageName,
        defaultOptions: {},
        totalResults,
    });

    // GET DATA FOR LIST --------------------------------------------------------------------------------------------------------------------

    const defaultColumns: Array<Extract<keyof TableType, string>> = [
        "bookingNumber",
        "courseType",
        "courseNumber",
        "booker",
        "attendee",
        "location",
        "firstCourseLesson",
        "invoice",
        "paymentMethod",
        "paymentDue",
        "paymentAmount",
        "setPaidAction",
    ];

    const {data, loading, error, refetch} = useGetUnpaidScheduledPaymentsQuery({
        variables: {
            options: options as GetUnpaidScheduledPaymentsQueryVariables["options"],
            bookingId,
        },
        pollInterval,
        fetchPolicy: "network-only",
    });

    React.useEffect(() => {
        refetch();
    }, [timeStamp, refetch]);

    const handleRefetch = () => {
        setTimeStamp?.(moment().unix());
        refetch();
    };

    useEffect(() => {
        totalResults !== data?.unpaidScheduledPayments?.total &&
            setTotalResults(data?.unpaidScheduledPayments?.total ?? 0);
    }, [data, totalResults]);

    useEffect(() => {
        if (error) console.log({error});
    }, [error]);

    // useEffect(() => {
    //     refetch();
    // }, [refetch, tableTitle, timeStamp]);

    const tableData = {
        dataSource: data?.unpaidScheduledPayments?.items ?? [],
        existMore: data?.unpaidScheduledPayments?.existsMore ?? false,
        total: data?.unpaidScheduledPayments?.total ?? 0,
        loading,
    };

    // MARK SELECTION OF PAYMENTS ----------------------------------------------------

    const [selection, setSelection] = useState<Array<string>>([]);

    const [updateManyScheduledPaymentMutation] = useUpdateManyScheduledPaymentIdsMutation({
        refetchQueries: [
            "GetUnpaidScheduledPayments",
            {query: GetPaidScheduledPaymentsDocument},
            "GetUnpaidScheduledPayments",
            {query: GetUnpaidScheduledPaymentsDocument, variables: {options}},
            "GetBookingById",
            {query: GetBookingByIdDocument, variables: {id: bookingId}},
            "GetBookingByIdWithCourseLessonBookings",
            {query: GetBookingByIdWithCourseLessonBookingsDocument, variables: {id: bookingId}},
        ],
    });

    const setAsPaid = async (date: Moment | null) => {
        const hide = message.loading(i18n.containers.payments.OpenPaymentsList.operate());

        try {
            await updateManyScheduledPaymentMutation({
                variables: {
                    paymentIds: selection,
                    editScheduledPaymentData: {
                        paymentDone: date ? date.toISOString() : moment().toISOString(),
                    },
                },
            }).then(() => {
                console.log("updateManyScheduledPaymentMutation: ", data);
            });
        } catch (error) {
            console.log("updateManyScheduledPaymentMutation: ", error);
        } finally {
            hide();
            message.success(i18n.containers.payments.OpenPaymentsList.markedAsPaidFinished(), 2);
        }
    };

    const onOk = (date: Moment | null) => {
        setAsPaid(date)
            .catch((err) => console.log(err))
            .then(() => setSelection([]));
    };

    const MarkSelection = () => {
        return (
            <CalendarPopover
                title={i18n.containers.payments.OpenPaymentsList.paymentDate()}
                buttonLeftText={i18n.containers.payments.OpenPaymentsList.changePaymentPopover.cancel()}
                buttonRightClick={(date) => onOk(date)}
                buttonRightText={i18n.containers.payments.OpenPaymentsList.accept()}
            >
                <Button type="primary" size="small">
                    {i18n.containers.payments.OpenPaymentsList.markAsPaid()}
                </Button>
            </CalendarPopover>
        );
    };

    // -------------------------------------------------------------------------------

    const [xmlBlob, setXmlBlob] = useState<Blob | undefined>();

    useEffect(() => {
        if (xmlBlob) {
            const url = window.URL.createObjectURL(xmlBlob);
            const link = document.createElement("a");

            link.href = url;
            link.setAttribute("download", `SEPA_XML.xml`);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode?.removeChild(link);

            setXmlBlob(undefined);
        }
    }, [xmlBlob]);

    const [createXmlModalOpen, setCreateXmlModalOpen] = useState(false);

    const ActionButtons = () => {
        return (
            <Space>
                {/* <SuperUserBadge>
                    <CreateDummyCamtFileModal
                        openPayments={data?.unpaidScheduledPayments?.items}
                    />
                </SuperUserBadge> */}
                <UploadSwissQrCamtModal refetch={handleRefetch} />
                <Button size="small" onClick={() => setCreateXmlModalOpen(!createXmlModalOpen)} style={{marginLeft: 7}}>
                    {"Lastschriftexport"}
                </Button>
            </Space>
        );
    };

    return (
        <>
            <CreateXmlModal
                open={createXmlModalOpen}
                setOpen={(open) => setCreateXmlModalOpen(open)}
                now={new Date()}
            />
            <Page
                data-testid="OpenPaymentsList"
                content={
                    <PaymentsList
                        loading={loading}
                        tableTitle={tableTitle ?? i18n.containers.payments.OpenPaymentsList.tableTitle()}
                        defaultColumns={customColumns ?? defaultColumns}
                        mandatoryColumns={mandatoryColumns}
                        tableData={tableData}
                        setOptions={setOptions}
                        options={options}
                        refetch={handleRefetch}
                        specialFunctions={
                            hideSpecialFunctions ? undefined : [<MarkSelection key="markSelectionPaid" />]
                        }
                        extraFunctions={[<Submenu key="submenu" refetch={handleRefetch} />]}
                        createXmlBtn={hideXmlBtn ? undefined : <ActionButtons />}
                        selection={(selectedItems: React.SetStateAction<Array<string>>) => setSelection(selectedItems)}
                        preserveSelectedRowKeys
                        tablesEnum={tablesEnum}
                        hidePagination={hidePagination}
                        style={style}
                        hiddenFilters={hiddenFilters}
                        minPadding={minPadding}
                        contentType="openPayment"
                        hideFooter={hideFooter}
                    />
                }
            />
        </>
    );
};

export default memo(OpenPaymentsList);
