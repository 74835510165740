import React, {FC, memo, useState} from "react";
import {MenuProps, Modal, message} from "antd";
import {useHistory} from "react-router";
import modal from "antd/lib/modal";
import {
    Booking,
    TemplateType,
    useConvertBookingToWaitlistMutation,
    useConvertWaitlistToBookingMutation,
    useGetWaitlistCountOfCourseQuery,
    useRemoveBookingsMutation,
    useSendBookingConfirmationMutation,
    useSendBookingInvoicesByBookingIdsMutation,
} from "../../../../generated/graphql";
import {EditParamsType, TableType} from "../BookingListColumns";
import i18n from "../../../../services/i18n";
import {SentMailsResult} from "../../../../../server/mailjet.types";
import ActionButtonGroup, {
    deleteMessageStateFromApolloQueryResult,
    useDeleteMessage,
} from "../../../../views/ActionButtonGroup/ActionButtonGroup";
import {isDefined} from "../../../../helpers/typeScriptHelpers";
import CancelSettledBookingAction from "../../CancelSettledBookingAction/CancelSettledBookingAction";
import MailTemplateEditor from "../../../templates/MailTemplateEditor/MailTemplateEditor";
import TransferAttendeeToAnotherCourse from "../../TransferAttendeeToAnotherCourse";
import FullyBookedAndDeleteModal from "../../../../views/FullyBookedAndDeleteModal/FullyBookedAndDeleteModal";
import {URL_LESSON_BOOKING_EDIT_ID} from "../../../../models/url";
import RenderResult from "./RenderResult";
import {knownErrorsBookingConfirmation, knownErrorsConvertToBooking} from "./knownErrors";
import {refetchQueriesForAll} from "./refetchQueries";
import {handleError} from "../../../../errors/handleErrors";

export type RenderActionsProps = {
    record: TableType;
    editLink: string;
    detailsLink?: string;
    actionColumn?: Function;
    selectedRowKeys?: Array<string>;
    refetch?: () => void;
    waitListCountFromBooking?: number;
};

const RenderActions: FC<RenderActionsProps> = ({
    record,
    editLink,
    detailsLink,
    actionColumn,
    selectedRowKeys,
    refetch,
    waitListCountFromBooking,
}) => {
    const [fullyBookedModalOpen, setFullyBookedModalOpen] = useState(false);
    const [copyAttendeeModalOpen, setCopyAttendeeModalOpen] = useState(false);
    const [transferAttendeeModalOpen, setTransferAttendeeModalOpen] = useState(false);
    const {
        id,
        bookingNumber,
        invoice,
        attendee,
        coursePriceId,
        coursePrice,
        courseLessons,
        bookingNumberPrefix,
        noticeStaff,
        paymentType,
        isSpecial,
        grossPrice,
        netPrice,
        vat,
        canceled,
        bookingType,
    } = record;
    const bookingId = id;
    const courseId = courseLessons[0].course.id;
    const bookingNumberString = bookingNumber.toString();
    const isSettled = isDefined(invoice) === true;
    const isCanceled = canceled !== null;
    const rowIsSelected = selectedRowKeys?.includes(bookingId);
    const email = attendee?.booker?.email;

    const history = useHistory();

    // CONVERT BOOKING ---------------------------------------------------------------------------

    const [convertToWaitlist] = useConvertBookingToWaitlistMutation({
        awaitRefetchQueries: true,
        refetchQueries: refetchQueriesForAll({
            bookingId,
            courseId,
        }),
    });

    const [convertToBooking] = useConvertWaitlistToBookingMutation({
        awaitRefetchQueries: true,
        refetchQueries: refetchQueriesForAll({
            bookingId,
            courseId,
        }),
    });

    // Convert to and from waitlist and booking -------------------------------------------------------------------------
    const checkFreePlacesBeforeConverting = async ({
        booking,
        convertTo,
    }: {
        booking: TableType;
        convertTo: "booking" | "waitlist";
    }) => {
        const freePlaces = booking.courseLessons[0].course.freePlaces;

        if (freePlaces <= 0 && convertTo === "booking") {
            modal.confirm({
                title: "Achtung, dieser Kurs bereits ausgebucht!",
                content: "Der Kurs hat keinen freien Platz mehr. Möchtest du die Buchung trotzdem durchführen?",
                onOk: () => {
                    handleConvertBooking({convertTo});
                },
                okText: "Trotzdem buchen",
            });
        } else {
            handleConvertBooking({convertTo});
        }
    };

    const [sendBookingConfirmation, {loading: sendBookingConfirmationLoading}] = useSendBookingConfirmationMutation({
        refetchQueries: refetchQueriesForAll({
            bookingId,
            courseId,
        }),
    });

    const triggerSendBookingConfirmation = async () => {
        const bookingOrWaitlistConfirmationText = "Buchungsbestätigung";

        try {
            message.loading({
                key: `sendMail`,
                content: i18n.containers.bookings.BookingSummary.sendBookingConfirmation.messages.loadingText({
                    bookingOrWaitlistConfirmationText,
                }),
            });

            await sendBookingConfirmation({variables: {bookingId}}).then((res) => {
                const sentMailResult: SentMailsResult = res.data?.sendBookingConfirmation;

                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (sentMailResult) {
                    const mailCount = sentMailResult.sentMailCount;
                    const emailText = mailCount === 1 ? "E-Mail" : "E-Mails";
                    const receivers = sentMailResult.sentMessages.map((message) => message.To[0].Email);
                    const receiverString = receivers.join(", ");

                    message.success({
                        key: "sendMail",
                        content: `${mailCount} ${emailText} erfolgreich versendet. Empfänger: ${receiverString}`,
                    });
                    Modal.destroyAll();
                }
            });
        } catch (error) {
            handleError(
                "sendBookingConfirmationError",
                error,
                "message",
                knownErrorsBookingConfirmation(bookingOrWaitlistConfirmationText),
            );
        }
    };

    const handleConvertBooking = async ({convertTo}: {convertTo: "booking" | "waitlist"}) => {
        const editUrl = editLink ? editLink.replace(":id", id) : undefined;

        try {
            message.loading({
                key: "convertingMessage",
                loading: true,
                content: convertTo === "booking" ? "Konvertiere Buchung..." : "Konvertiere Warteliste...",
            });
            if (convertTo === "booking") {
                console.log("DEBUG::: => convertToBooking");

                await convertToBooking({
                    variables: {
                        bookingId,
                    },
                }).then((res) => {
                    message.success("Wartelistenplatz erfolgreich in Buchung umgewandelt");

                    const prefixedBookingNumber = `${res.data?.convertWaitlistToBooking.bookingNumberPrefix} ${res.data?.convertWaitlistToBooking.bookingNumber}`;
                    const attendeesName = `${res.data?.convertWaitlistToBooking.attendee?.firstname} ${res.data?.convertWaitlistToBooking.attendee?.lastname}`;

                    if (editUrl) {
                        modal.confirm({
                            icon: null,
                            closable: true,
                            content: (
                                <RenderResult
                                    convertTo={convertTo}
                                    attendeesName={attendeesName}
                                    prefixedBookingNumber={prefixedBookingNumber}
                                    loading={sendBookingConfirmationLoading}
                                    triggerSendBookingConfirmation={triggerSendBookingConfirmation}
                                />
                            ),
                            onOk: () =>
                                history.push({
                                    pathname: editUrl,
                                    state: {
                                        refferer: history.location.pathname,
                                        from: {
                                            pathname: history.location.pathname,
                                        },
                                    },
                                }),
                            okText: "Details der Buchung",
                            okButtonProps: {
                                type: "default",
                                style: {
                                    width: "calc(50% - 10px)",
                                    margin: "0px 5px",
                                },
                            },
                            cancelText: "Okay",
                            cancelButtonProps: {
                                type: "primary",
                                style: {
                                    width: "calc(50% - 10px)",
                                    margin: "0px 5px",
                                },
                            },
                        });
                    }
                });
            } else {
                console.log("DEBUG::: => convertToWaitlist");
                await convertToWaitlist({
                    variables: {
                        bookingId,
                    },
                }).then((res) => {
                    message.success("Buchung erfolgreich in Wartelistenplatz umgewandelt");

                    const prefixedBookingNumber = `${res.data?.convertBookingToWaitlist.bookingNumberPrefix} ${res.data?.convertBookingToWaitlist.bookingNumber}`;
                    const attendeesName = `${res.data?.convertBookingToWaitlist.attendee?.firstname} ${res.data?.convertBookingToWaitlist.attendee?.lastname}`;

                    if (editUrl) {
                        modal.confirm({
                            icon: null,
                            closable: true,
                            content: (
                                <RenderResult
                                    convertTo={convertTo}
                                    attendeesName={attendeesName}
                                    prefixedBookingNumber={prefixedBookingNumber}
                                    loading={sendBookingConfirmationLoading}
                                    triggerSendBookingConfirmation={triggerSendBookingConfirmation}
                                />
                            ),
                            onOk: () =>
                                history.push({
                                    pathname: editUrl,
                                    state: {
                                        refferer: history.location.pathname,
                                        from: {
                                            pathname: history.location.pathname,
                                        },
                                    },
                                }),
                            okText: "Details der Buchung",
                            okButtonProps: {
                                type: "default",
                                style: {
                                    width: "calc(50% - 10px)",
                                    margin: "0px 5px",
                                },
                            },
                            cancelText: "Okay",
                            cancelButtonProps: {
                                type: "primary",
                                style: {
                                    width: "calc(50% - 10px)",
                                    margin: "0px 5px",
                                },
                            },
                        });
                    }
                });
            }
        } catch (error) {
            console.log(error);

            handleError("transferAttendeeToCourse", error, "modal", knownErrorsConvertToBooking);
        } finally {
            message.destroy("convertingMessage");
        }
    };

    // SEND MAIL ----------------------------------------------------------------------------
    const [sendMultipleIncoiceMails] = useSendBookingInvoicesByBookingIdsMutation({});

    const [showEmailEditor, setShowEmailEditor] = useState(false);

    const handleSendingMultipleMails = async (send: boolean) => {
        if (send) {
            try {
                message.loading({
                    key: "sendMail",
                    content: "E-Mails werden versendet...",
                    duration: 0,
                });

                await sendMultipleIncoiceMails({
                    variables: {bookingIds: [bookingId]},
                }).then((res) => {
                    // console.log("sendMultipleIncoiceMails res:::", res);
                    if (res.data?.sendBookingInvoicesByBookingIds) {
                        const sentMailResult: SentMailsResult = res.data.sendBookingInvoicesByBookingIds;
                        const mailCount = sentMailResult.sentMailCount;
                        const emailText = mailCount === 1 ? "E-Mail" : "E-Mails";
                        const receivers = sentMailResult.sentMessages.map((message) => message.To[0].Email);
                        const receiverString = receivers.join(", ");

                        message.success({
                            key: "sendMail",
                            content: `${mailCount} ${emailText} erfolgreich versendet. Empfänger: ${receiverString}`,
                        });
                    }
                });
            } catch (error) {
                throw new Error(`Error useSendBookingInvoiceMutation: ${error}`);
            }
            setShowEmailEditor(false);
        } else {
            setShowEmailEditor(false);
        }
    };

    // DELETE BOOKING ---------------------------------------------------------------------------

    const deleteDisabledText = i18n.containers.bookings.BookingList.deleteModal.warningMessage({bookingNumberString});

    const [
        deleteBooking,
        {data: deleteBookingData, loading: deleteBookingLoading, error: deleteBookingError},
    ] = useRemoveBookingsMutation({
        awaitRefetchQueries: true,
        refetchQueries: refetchQueriesForAll({
            bookingId,
            courseId,
        }),
    });

    const deleteMessageState = deleteMessageStateFromApolloQueryResult({
        data: deleteBookingData,
        loading: deleteBookingLoading,
        error: deleteBookingError,
    });

    useDeleteMessage({
        state: deleteMessageState,
        loadingMessage: i18n.containers.bookings.BookingList.deleteModal.loadingMessage({bookingNumberString}),
        successMessage: i18n.containers.bookings.BookingList.deleteModal.successMessage({bookingNumberString}),
        errorMessage: deleteBookingError?.message ?? "",
    });

    const editParams: EditParamsType = {
        bookingId,
        attendee,
        coursePriceId,
        coursePrice,
        courseLessons,
        bookingNumberPrefix,
        noticeStaff,
        paymentType,
        isSpecial,
        grossPrice,
        netPrice,
        vat,
    };

    const bookingToDeleteId: Booking["id"] = bookingId;

    // BEFORE //////////////////////////////////////////////////////////////////////////
    // //////////// const {data: waitlistCountData} = useGetWaitlistCountOfCourseQuery({
    // ////////////     skip: !courseId || record.waitListCount !== 0,
    // ////////////     variables: {id: courseId},
    // ////////////     // fetchPolicy: "network-only", DEBUG 2024-03-06
    // //////////// });

    // //////////// const maxAttendees = record.courseLessons[0].course.maxAttendees;
    // //////////// const waitlistCount =
    // ////////////     waitlistCountData?.getWaitlistCountOfCourse ??
    // ////////////     record.waitListCount ??
    // ////////////     0;
    // //////////// const bookableSlots = maxAttendees - waitlistCount;

    // //////////// React.useEffect(() => {
    // ////////////     console.log("!!! D I D  R U N !!!");
    // ////////////     console.log("waitlistCount:::", waitlistCount);
    // //////////// }, [waitlistCount]);
    // BEFORE //////////////////////////////////////////////////////////////////////////

    // the part below should be refactored, it should be possible to get the waitlist count from the record
    // const {data: waitlistCountData} = useGetWaitlistCountOfCourseQuery({
    //     skip: !courseId || record.waitListCount !== 0,
    //     variables: {id: courseId},
    //     // fetchPolicy: "network-only", DEBUG 2024-03-06 HERE
    // });

    const maxAttendees = record.courseLessons[0].course.maxAttendees;
    const waitlistCount = waitListCountFromBooking ?? 0;
    // const waitlistCount =
    //     waitlistCountData?.getWaitlistCountOfCourse ??
    //     record.waitListCount ??
    //     0;
    const bookableSlots = maxAttendees - waitlistCount;

    // React.useEffect(() => {
    //     console.log("!!! D I D  R U N !!!");
    //     console.log("waitlistCount:::", waitlistCount);
    // }, [waitlistCount]);

    // React.useEffect(() => {
    //     console.log("??? D I D  R U N ???");
    //     console.log("record.waitListCount:::", record.waitListCount);
    // }, [record.waitListCount]);

    const deleteOnConfirm = async () => {
        if (waitlistCount > 0 && bookableSlots >= 0 && bookingType !== "Waitlist") {
            setFullyBookedModalOpen(true);
        } else {
            await deleteBooking({
                variables: {
                    ids: [bookingToDeleteId],
                },
                refetchQueries: refetchQueriesForAll({
                    bookingId,
                    courseId,
                }),
                awaitRefetchQueries: true,
            });
        }
    };

    // CONVERT BOOKINGS -----------------------------------------------------------------------------------------
    type MenuItems = MenuProps["items"];

    const getMenuItems = (): MenuItems => {
        const items: MenuItems = [];

        const bookingIsSettled = Boolean(isDefined(record.invoice));

        if (record.bookingType !== "PlaceReservation") {
            items.push(
                {
                    label: "Teilnehmer in anderen Kurs verschieben",
                    key: "transfer-attendee",
                    onClick: () => setTransferAttendeeModalOpen(true),
                },
                {
                    label: "Teilnehmer in anderen Kurs kopieren",
                    key: "copy-attendee",
                    onClick: () => setCopyAttendeeModalOpen(true),
                },
            );
        }

        if (bookingIsSettled) {
            return items;
        }

        if (record.bookingType === "Waitlist") {
            items.push({
                label: "Teilnehmer in den Kurs buchen",
                key: "item-1",
                onClick: () => {
                    checkFreePlacesBeforeConverting({
                        booking: record,
                        convertTo: "booking",
                    });
                },
            });
        } else if (record.bookingType === "Booking") {
            items.push({
                label: "Teilnehmer auf die Warteliste setzen",
                key: "item-2",
                onClick: () => {
                    checkFreePlacesBeforeConverting({
                        booking: record,
                        convertTo: "waitlist",
                    });
                },
            });
        }

        return items;
    };

    const SettledBookingAction = ({refetch}: {refetch?: () => void}) => {
        if (typeof actionColumn === "function") {
            return actionColumn(record);
        }

        return (
            <CancelSettledBookingAction
                record={record}
                moreMenuItems={isCanceled ? undefined : getMenuItems()}
                refetch={refetch}
            />
        );
    };

    // RENDER ACTIONS ------------------------------------------------------------------------------------------
    return (
        <>
            {rowIsSelected && (
                <MailTemplateEditor
                    asModal
                    bookingId={bookingId}
                    visible={showEmailEditor}
                    setVisibility={(e: boolean) => setShowEmailEditor(e)}
                    templateType={TemplateType.SettlementConfirmation}
                    useCase="SENDMAIL"
                    confirmAction={async (e) => handleSendingMultipleMails(e)}
                    // receivers={[record.attendee?.booker?.email ?? ""]}
                    receivers={[email ?? ""]}
                />
            )}
            {transferAttendeeModalOpen && (
                <TransferAttendeeToAnotherCourse
                    transformAction="transfer"
                    bookingId={bookingId}
                    currentCourseId={courseId}
                    modalOpen={transferAttendeeModalOpen}
                    setModalOpen={(e: boolean) => setTransferAttendeeModalOpen(e)}
                    attendeeFullName={`${record.attendee?.firstname} ${record.attendee?.lastname}`}
                    isSettled={isSettled}
                />
            )}
            {copyAttendeeModalOpen && (
                <TransferAttendeeToAnotherCourse
                    transformAction="copy"
                    bookingId={bookingId}
                    currentCourseId={courseId}
                    modalOpen={copyAttendeeModalOpen}
                    setModalOpen={(e: boolean) => setCopyAttendeeModalOpen(e)}
                    attendeeFullName={`${record.attendee?.firstname} ${record.attendee?.lastname}`}
                    isSettled={isSettled}
                />
            )}
            {fullyBookedModalOpen && (
                <FullyBookedAndDeleteModal
                    record={record}
                    editParams={editParams}
                    modalVisible={fullyBookedModalOpen}
                    setModalVisible={(e: boolean) => setFullyBookedModalOpen(e)}
                    refetch={refetch}
                />
            )}
            {isSettled === false ? (
                <ActionButtonGroup
                    editUrl={editLink ? editLink.replace(":id", id) : URL_LESSON_BOOKING_EDIT_ID.replace(":id", id)}
                    editParams={editParams}
                    detailsUrl={detailsLink ? detailsLink.replace(":id", id) : undefined}
                    detailsState={record}
                    detailsTestId="BookingDetailsButton"
                    deleteDisabledText={deleteDisabledText}
                    deleteTestId="BookingDeleteButton"
                    editTestId="BookingEditButton"
                    deleteConfirmText={i18n.containers.bookings.BookingList.deleteModal.confirmMessage({
                        bookingNumberString,
                    })}
                    deleteOnConfirm={deleteOnConfirm}
                    moreMenuItems={getMenuItems()?.length ? getMenuItems() : undefined}
                />
            ) : (
                <SettledBookingAction refetch={refetch} />
            )}
        </>
    );
};

export default memo(RenderActions);
