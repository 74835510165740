import React, {FC, memo} from "react";
import {Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import PanelCard from "../../../../../views/PanelCard/PanelCard";
import i18n from "../../../../../services/i18n";
import {marginXs} from "../../../../../styles/layout";
import Button from "../../../../../views/Button/Button";
import {AnnotationTextField} from "../../../../../views/PanelCardCourseData/AnnotationTextField";
import {GetBookingByIdWithCourseLessonBookingsQuery} from "../../../../../generated/graphql";

type AnnotationCoursePanelProps = {
    booking: GetBookingByIdWithCourseLessonBookingsQuery["bookingByIdWithCourseLessonBookings"];
    shownLessonCount: number;
    gapAfter?: boolean;
    className?: string;
};

const AnnotationCoursePanel: FC<AnnotationCoursePanelProps> = ({booking, shownLessonCount, gapAfter, className}) => {
    const course = booking.courseLessons[0].course;

    return (
        <PanelCard
            className={className}
            columns={1}
            gapAfter={gapAfter}
            title={i18n.containers.courses.CourseCreate.courseInfo.label()}
            style={{
                overflow: "hidden",
                // height: `calc(100% - ${marginXs})`,
            }}
            extra={
                <Tooltip title={i18n.containers.courses.CourseCreate.courseInfo.description()} placement="left">
                    <Button type="link" style={{margin: 0}}>
                        <InfoCircleOutlined />
                    </Button>
                </Tooltip>
            }
        >
            <div
                style={{
                    paddingTop: `calc(${marginXs} + 2px)`,
                }}
            >
                <AnnotationTextField
                    courseId={course.id}
                    courseLessonCount={shownLessonCount}
                    placeholder={i18n.containers.courses.CourseCreate.courseInfo.placeholder()}
                />
            </div>
        </PanelCard>
    );
};

export default memo(AnnotationCoursePanel);
