import {Card, Modal, Space, Spin} from "antd";
import {Formik} from "formik";
import moment from "moment";
import React, {FC, memo, useState} from "react";
import {KnownError} from "../../../errors/handleErrors";
import {
    CreateSepaXmlByTimeRangeInput,
    GetExportedSepaXmlsDocument,
    GetPaidScheduledPaymentsDocument,
    GetUnpaidScheduledPaymentsDocument,
    NewSepaXmlInput,
    useCreateSepaXmlByTimeRangeMutation,
} from "../../../generated/graphql";
import {colorBlueLight} from "../../../styles/colors";
import {panelWidth} from "../../../styles/layout";
import AlertCard from "../../../views/AlertCard/AlertCard";
import ExportedSepaXmlList from "../../exportedSepaXml/ExportedSepaXmlList";
import {convertXmlData} from "./convertXmlData";
import CreateXmlModalPreview, {CreateXmlModalPreviewProps} from "./CreateXmlModalPreview";
import CreateXmlModalTable from "./CreateXmlModalTable";
import InputForm from "./InputForm";
import Description from "./Description";
// import ExportedSepaXmlList from "./ExportedSepaXmlList";

type CreateXmlModalProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    now: Date;
};

export type XmlResponse = {object: NewSepaXmlInput; xml: string};

const CreateXmlModal: FC<CreateXmlModalProps> = ({open, setOpen, now}) => {
    // https://www.mobilefish.com/services/sepa_xml_validation/sepa_xml_validation.php

    const cardWidth = panelWidth - 45;

    const [createSepaXmlByTimeRangeInput, setCreateSepaXmlByTimeRangeInput] = useState<CreateSepaXmlByTimeRangeInput>({
        startDate: undefined,
        endDate: undefined,
        collectionDate: undefined,
        saveFile: false,
        maxAmount: undefined,
        maxMoneyAmount: undefined,
    });

    const [errors, setErrors] = useState<Array<{errorTitle: string; errorMsg: string}> | undefined>();

    const [updating, setUpdating] = useState(false);
    const [xmlData, setXmlData] = useState<XmlResponse>();
    const [saveFile] = useState(false);

    const [getXmlData, {loading}] = useCreateSepaXmlByTimeRangeMutation();

    const ErrorSegment = ({errors}: {errors?: Array<{errorTitle: string; errorMsg: string}>}) => {
        if (!errors || errors.length === 0) {
            return null;
        }

        const ErrorList: Array<JSX.Element> = [];

        errors.forEach((error) => {
            ErrorList.push(
                <AlertCard
                    key={error.errorTitle}
                    description={error.errorMsg}
                    title={error.errorTitle}
                    showIcon={false}
                    type="DANGER"
                />,
            );
        });

        return <>{ErrorList}</>;
    };

    // type KnownError = {key: string; title: string; msg: string};

    const knownErrors: Array<KnownError> = [
        {
            key: "No scheduled payments found",
            title: "Keine Zahlungen",
            msg: "Keine Sepa-Zahlungen für den angegebenen Zeitraum gefunden",
        },
        {
            key: "No company creditorId",
            title: "Keine Gläubiger ID",
            msg: "Um die XML-Datei zu erstellen, muss eine Gläubiger ID in den Einstellungen hinterlegt sein.",
        },
        {
            key: "No attendee or Booker found",
            title: "Bucher oder Teilnehmer nicht gefunden",
            msg:
                "Bei einem oder mehreren Buchungen konnte kein Teilnehmer oder Bucher gefunden werden. Bitte überprüfe die Buchungen.",
        },
        {
            key: "No course found",
            title: "Kurs nicht gefunden",
            msg: "Bei einer oder mehreren Buchungen konnte kein Kurs gefunden werden. Bitte überprüfe die Buchungen.",
        },
        {
            key: "No company name found",
            title: "Fehlende Unternehmensdaten",
            msg: "Um die XML-Datei zu erstellen, muss ein Unternehmensname in den Einstellungen hinterlegt sein.",
        },
        {
            key: "No company IBAN found",
            title: "Fehlende Iban",
            msg: "Um die XML-Datei zu erstellen, muss eine Iban in den Einstellungen hinterlegt sein.",
        },
        {
            key: "creditorIBAN has invalid IBAN",
            title: "Fehlerhafte Iban",
            msg:
                "Die angegebene Iban in unter Einstellunge > Unternehmensdaten ist fehlerhaft. Bitte überprüfe die Iban.",
        },
        {
            key: "No company BIC found",
            title: "Fehlende BIC",
            msg: "Um die XML-Datei zu erstellen, muss eine BIC in den Einstellungen hinterlegt sein.",
        },
        {
            key: "country mismatch in BIC/IBAN",
            title: "Fehlerhafte BIC",
            msg:
                "Die angegebene BIC in unter Einstellunge > Unternehmensdaten ist fehlerhaft. Bitte überprüfe die BIC.",
        },
        {
            key: "creditorId is invalid",
            title: "Fehlerhafte Gläubiger ID",
            msg:
                "Die angegebene Gläubiger in unter Einstellunge > Unternehmensdaten ID ist fehlerhaft. Bitte überprüfe die Gläubiger ID.",
        },
        {
            key: "No sepaAccountholder",
            title: "Fehlende Kontoinhaberdaten",
            msg:
                "Um die XML-Datei zu erstellen, muss ein Kontoinhaber in den Einstellungen hinterlegt sein. Diese fehlen bei mindestens einem Bucher.",
        },
    ];

    const handleErrors = (errors: any) => {
        console.log("=> handleErrors: ", errors);
        if (errors.graphQLErrors) {
            console.log("errors.graphQLErrors: ", errors.graphQLErrors);

            errors.graphQLErrors.forEach((gqlError: any) => {
                const gqlErrorMsg = gqlError.message;

                setErrors([
                    {
                        errorTitle: "Fehler",
                        errorMsg: gqlErrorMsg,
                    },
                ]);

                knownErrors.forEach((knownError) => {
                    if (gqlErrorMsg.includes(knownError.key)) {
                        setErrors([
                            {
                                errorTitle: knownError.title,
                                errorMsg: knownError.msg,
                            },
                        ]);
                    }
                });
            });
        } else if (errors.networkError) {
            console.log("networkError: ", errors.networkError);
        } else {
            console.log("errors: ", errors);
        }
    };

    const loadXmlData = async (updatedDates?: CreateSepaXmlByTimeRangeInput) => {
        setErrors(undefined);
        try {
            const updatedInput = updatedDates ? updatedDates : createSepaXmlByTimeRangeInput;

            // updatedInput.maxAmount = values.maxAmount
            //     ? values.maxAmount
            //     : undefined;
            // updatedInput.maxMoneyAmount = values.maxMoneyAmount
            //     ? values.maxMoneyAmount
            //     : undefined;

            await getXmlData({
                variables: {createSepaXmlByTimeRangeInput: {...updatedInput}},
                refetchQueries:
                    updatedInput.saveFile === true
                        ? [
                              "getUnpaidScheduledPayments",
                              {query: GetUnpaidScheduledPaymentsDocument},
                              "getPaidScheduledPayments",
                              {query: GetPaidScheduledPaymentsDocument},
                              "GetExportedSepaXmls",
                              {query: GetExportedSepaXmlsDocument},
                              "CreateSepaXmlByTimeRange",
                          ]
                        : [],
            }).then((response) => {
                if (response.data?.createSepaXmlByTimeRange) {
                    setXmlData(response.data.createSepaXmlByTimeRange);
                }
                if (response.data?.createSepaXmlByTimeRange.savedFile === true) {
                    console.log("should download");
                    downLoadXmlFile({
                        xmlData: response.data.createSepaXmlByTimeRange,
                    });
                }

                // console.log("response: ", response);
            });
        } catch (error) {
            handleErrors(error);
        }
    };

    const downLoadXmlFile = ({xmlData}: {xmlData?: XmlResponse}) => {
        if (!xmlData?.xml) {
            return;
        }

        const blob = new Blob([xmlData.xml], {
            type: "application/xml; charset=utf-8",
        });

        // ----------------------------------------------------------------------------------------------

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        const today = moment().format("YYYY-MM-DD_HHmm");

        link.href = url;
        link.setAttribute("download", `SEPA_${today}.xml`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
    };

    const XmlPreview = ({xmlData}: {xmlData?: XmlResponse}) => {
        const [activeTabKey, setActiveTab] = useState("tab1");

        if (!xmlData || !xmlData.object) {
            // setActiveTab("tab3");
            setErrors([
                {
                    errorMsg: knownErrors[0].msg,
                    errorTitle: knownErrors[0].title,
                },
            ]);

            return (
                <Space direction="vertical" style={{width: "100%"}}>
                    <ErrorSegment errors={errors} />
                    <ExportedSepaXmlList />
                </Space>
            );
        }
        // CONVERT THE FETCHED XML DATA TO THE RIGHT FORMAT ----------------------------------------------

        const convertedXmlData: CreateXmlModalPreviewProps = convertXmlData({
            xmlData,
        });

        const {documentInfo, creditorInfo, transactionInfo} = convertedXmlData;

        // TABLE VIEW ----------------------------------------------------------------------------------

        const tab1 = (
            <CreateXmlModalTable
                documentInfo={documentInfo}
                creditorInfo={creditorInfo}
                transactionInfo={transactionInfo}
            />
        );

        // CARD VIEW ----------------------------------------------------------------------------------

        const tab2 = (
            <CreateXmlModalPreview
                documentInfo={documentInfo}
                creditorInfo={creditorInfo}
                transactionInfo={transactionInfo}
                panelWidth={panelWidth}
                cardWidth={cardWidth}
            />
        );

        // EXPORT HISTORY ----------------------------------------------------------------------------------<

        const tab3 = <ExportedSepaXmlList />;

        // CARD ----------------------------------------------------------------------------------

        const tabList = [
            {
                key: "tab1",
                tab: "Tabellenansicht",
            },
            {
                key: "tab2",
                tab: "Vorschau Begleitzettel",
            },
            {
                key: "tab3",
                tab: "Exporthistorie",
            },
        ];

        const contentList: Record<string, React.ReactNode> = {
            tab1,
            tab2,
            tab3,
        };

        const onTabChange = (key: string) => {
            setActiveTab(key);
        };

        return (
            <Card
                style={{
                    // width: cardWidth,
                    maxWidth: cardWidth,
                    width: "100%",
                    boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.3)",
                    display: "flex",
                    flexDirection: "column",
                    // border: "5px dotted red",
                    // display: "flex",
                    borderColor: colorBlueLight,
                    borderRadius: "10px",
                    // boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.3)",
                    // marginBottom: 30,
                    // flexDirection: "column",
                }}
                tabList={tabList}
                activeTabKey={activeTabKey}
                onTabChange={(key) => {
                    onTabChange(key);
                }}
            >
                {contentList[activeTabKey]}
            </Card>
        );
    };

    //  ----------------------------------------------------------------------------------------------------------------

    const initialValues: CreateSepaXmlByTimeRangeInput = {
        startDate: undefined,
        endDate: undefined, // endDate: moment().format("YYYY-MM-DD"),
        collectionDate: undefined, // collectionDate: moment().format("YYYY-MM-DD"),
        maxAmount: undefined,
        maxMoneyAmount: undefined,
    };

    const refetchResults = async ({values}: {values: CreateSepaXmlByTimeRangeInput}) => {
        // console.log("refetchResults values:", values);

        // const updatedDates: CreateSepaXmlByTimeRangeInput = {
        //     startDate: values.startDate
        //         ? moment(values.startDate).format("YYYY-MM-DD")
        //         : undefined,
        //     endDate: values.endDate
        //         ? moment(values.endDate).format("YYYY-MM-DD")
        //         : undefined,
        //     collectionDate: values.collectionDate
        //         ? moment(values.collectionDate).format("YYYY-MM-DD")
        //         : undefined,
        // };

        const updatedDates: CreateSepaXmlByTimeRangeInput = {
            startDate: values.startDate
                ? moment(values.startDate)
                      .startOf("day")
                      .utc(true) // set to the start of the day in UTC
                : undefined,
            endDate: values.endDate
                ? moment(values.endDate)
                      .endOf("day")
                      .utc(true) // set to the start of the day in UTC
                : undefined,
            collectionDate: values.collectionDate
                ? moment(values.collectionDate)
                      .startOf("day")
                      .utc(true) // set to the start of the day in UTC
                : undefined,
        };

        setCreateSepaXmlByTimeRangeInput({
            ...updatedDates,
            saveFile,
            maxAmount: values.maxAmount,
            maxMoneyAmount: values.maxMoneyAmount,
        });

        await loadXmlData({
            ...updatedDates,
            saveFile,
            maxAmount: values.maxAmount,
            maxMoneyAmount: values.maxMoneyAmount,
        });
    };

    const paymentCount = xmlData?.object.positions[0].payments.length;
    const noPayments = paymentCount === 0;

    const currentDateIsBeforeEndDate = moment(now).isBefore(createSepaXmlByTimeRangeInput.endDate + "T00:00:00.000Z");

    return (
        <Modal
            title="Sepa-Xml-Datei erstellen"
            open={open}
            onCancel={() => {
                setOpen(false);
                setXmlData(undefined);
            }}
            width="95%"
            style={{maxWidth: panelWidth}}
            destroyOnClose
            footer={null}
        >
            <Space direction="vertical">
                <Description />
                <Space direction="vertical" style={{width: "100%"}}>
                    <Card
                        title="Zeitraum wählen"
                        style={{
                            display: "flex",
                            borderColor: colorBlueLight,
                            borderRadius: "10px",
                            boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.3)",
                            marginBottom: 30,
                            flexDirection: "column",
                            width: "100%",
                        }}
                    >
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            onSubmit={(values) => {
                                // console.log("onSubmit values:", values);

                                const updatedDates: CreateSepaXmlByTimeRangeInput = {
                                    startDate: values.startDate
                                        ? moment(values.startDate)
                                              .startOf("day")
                                              .utc(true) // set to the start of the day in UTC
                                        : undefined,
                                    endDate: values.endDate
                                        ? moment(values.endDate)
                                              .endOf("day")
                                              .utc(true) // set to the start of the day in UTC
                                        : undefined,
                                    collectionDate: values.collectionDate
                                        ? moment(values.collectionDate)
                                              .startOf("day")
                                              .utc(true) // set to the start of the day in UTC
                                        : undefined,
                                };

                                setCreateSepaXmlByTimeRangeInput({
                                    ...updatedDates,
                                    saveFile,
                                    maxAmount: values.maxAmount,
                                    maxMoneyAmount: values.maxMoneyAmount,
                                });

                                loadXmlData({
                                    ...updatedDates,
                                    saveFile,
                                    maxAmount: values.maxAmount,
                                    maxMoneyAmount: values.maxMoneyAmount,
                                });
                            }}
                        >
                            {(formikProps) => {
                                return (
                                    <InputForm
                                        formikProps={formikProps}
                                        currentDateIsBeforeEndDate={currentDateIsBeforeEndDate}
                                        createSepaXmlByTimeRangeInput={createSepaXmlByTimeRangeInput}
                                        setUpdating={setUpdating}
                                        refetchResults={refetchResults}
                                        // saveAndDownload={saveAndDownload}
                                        setCreateSepaXmlByTimeRangeInput={setCreateSepaXmlByTimeRangeInput}
                                        setXmlData={setXmlData}
                                        loadXmlData={loadXmlData}
                                        noPayments={noPayments}
                                        paymentCount={paymentCount}
                                        errors={errors}
                                    />
                                );
                            }}
                        </Formik>
                    </Card>
                </Space>
                {updating || loading ? (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Spin />
                    </div>
                ) : (
                    <>
                        {errors ? (
                            <>
                                <ErrorSegment errors={errors} />
                                {errors[0].errorTitle === "Keine Zahlungen" && <ExportedSepaXmlList />}
                            </>
                        ) : (
                            <XmlPreview xmlData={xmlData} />
                        )}
                    </>
                )}
            </Space>
        </Modal>
    );
};

export default memo(CreateXmlModal);
