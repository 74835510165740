import {red} from "@ant-design/colors";
import {Descriptions, Space, Table, TableProps} from "antd";
import React, {memo} from "react";
import {renderContinuousText} from "../../../views/TableColumnRenderers/TableColumnRenderers";

type CreateXmlModalTableProps = {
    documentInfo: Array<{label: string; value: string | number}>;
    creditorInfo: Array<{label: string; value: string}>;
    transactionInfo: Array<Array<{label: string; value: string | number}>>;
};

const CreateXmlModalTable = (props: CreateXmlModalTableProps) => {
    const {documentInfo, creditorInfo, transactionInfo} = props;

    const TableHeaderDescription = ({
        content,
        title,
    }: {
        content: Array<{label: string; value: string}>;
        title?: string;
    }) => {
        return (
            <Descriptions
                title={title}
                bordered={true}
                size="small"
                column={8}
                layout="vertical"
                labelStyle={{fontWeight: 500}}
            >
                {content.map((entry, index) => {
                    return (
                        <Descriptions.Item key={`${entry.label}`} label={entry.label}>
                            <div>{entry.value}</div>
                        </Descriptions.Item>
                    );
                })}
            </Descriptions>
        );
    };

    type ColumnsType =
        | "Debitor"
        | "Debitor BIC"
        | "Debitor IBAN"
        | "Betrag"
        | "Mandatsreferenz"
        | "Unterschriftsdatum"
        | "Verwendungszweck";
    type TableType = Array<{label: ColumnsType; value: string}>;

    const tableData = (transactionInfo: Array<TableType>) => {
        const columns: TableProps<TableType>["columns"] = [];
        const dataSource: TableProps<TableType>["dataSource"] = [];

        if (transactionInfo.length === 0) return {columns, dataSource};

        Object.values(transactionInfo[0]).forEach((entry, index) => {
            const {label} = entry;

            const defaultStyle: React.CSSProperties = {
                textAlign: "right",
                whiteSpace: "nowrap",
            };

            const zeroValuesStyle: React.CSSProperties = {
                textAlign: "right",
                whiteSpace: "nowrap",
                color: red[5],
                textDecoration: `solid line-through ${red[5]}`,
            };

            const valueStartsWithZerro = (value: string) => {
                return value.startsWith("0.00");
            };

            columns.push({
                title: label,
                dataIndex: label,
                key: label,
                render: (value, record, index) => {
                    if (label === "Betrag") {
                        return (
                            <div style={valueStartsWithZerro(value) ? zeroValuesStyle : defaultStyle}>
                                {value.replace(".", ",")}
                            </div>
                        );
                    } else if (label === "Verwendungszweck") {
                        return renderContinuousText(value, 150);
                    }

                    return value;
                },
                sorter: (a, b) => {
                    if (label === "Betrag") {
                        const aAmount = Number(a[label].split(" ")[0].replace(",", "."));
                        const bAmount = Number(b[label].split(" ")[0].replace(",", "."));

                        return aAmount - bAmount;
                    }

                    if (a[label] < b[label]) {
                        return -1;
                    }
                    if (a[label] > b[label]) {
                        return 1;
                    }

                    return 0;
                },
            });
        });

        transactionInfo.forEach((transaction, index) => {
            const row: TableType = [];

            transaction.forEach((entry) => {
                const {label, value} = entry;

                row[label] = value;
            });

            dataSource.push(row);
        });

        return {columns, dataSource};
    };

    if (tableData(transactionInfo as Array<TableType>).dataSource.length === 0)
        return <div>{"Keine Einträge gefunden"}</div>;

    return (
        <Space
            direction="vertical"
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <TableHeaderDescription content={documentInfo} title="Dokument Informationen" />
            <TableHeaderDescription content={creditorInfo} title="Kreditor" />
            <div style={{fontSize: "1.2em", fontWeight: 650}}>{"Transaktionen"}</div>
            <Table
                size="small"
                pagination={{
                    pageSize: 50,
                    showSizeChanger: true,
                    total: transactionInfo.length,
                    defaultPageSize: 100,
                }}
                columns={tableData(transactionInfo as Array<TableType>).columns}
                dataSource={tableData(transactionInfo as Array<TableType>).dataSource}
                scroll={{y: "100%"}}
            />
        </Space>
    );
};

export default memo(CreateXmlModalTable);
