import React, {FC, memo, useState} from "react";
import {Button, Modal, Space} from "antd";
import {PlusCircleOutlined, SaveOutlined} from "@ant-design/icons";
import {Formik} from "formik";
import css from "./AddNamedScheduledPayment.less";
import {
    AddNamedScheduledPaymentInput,
    GetBookingByIdDocument,
    GetBookingByIdWithCourseLessonBookingsDocument,
    GetPaidScheduledPaymentsDocument,
    GetUnpaidScheduledPaymentsDocument,
    MonthlySchedule,
    useAddNamedScheduledPaymentMutation,
} from "../../generated/graphql";
import {panelWidth} from "../../styles/layout";
import AddScheduledPaymetTabs from "./AddScheduledPaymetTabs";
import {options} from "./AddNamedScheduledPaymentForm";
import LoadingContent from "../LoadingContent/LoadingContent";
import {KnownError, handleError} from "../../errors/handleErrors";
import {AddNamedScheduledPaymentValidation} from "./AddNamedScheduledPaymentValidation";

type AddNamedScheduledPaymentProps = {
    bookingId: string;
    monthlySchedule?: MonthlySchedule;
    setTimeStamp?: (timeStamp: number) => void;
};

const AddNamedScheduledPayment: FC<AddNamedScheduledPaymentProps> = ({bookingId, monthlySchedule, setTimeStamp}) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const refetchQueries = [
        "GetUnpaidScheduledPayments",
        {query: GetPaidScheduledPaymentsDocument},
        "GetUnpaidScheduledPayments",
        {
            query: GetUnpaidScheduledPaymentsDocument,
        },
        "GetBookingById",
        {
            query: GetBookingByIdDocument,
            variables: {id: bookingId},
        },
        "GetBookingByIdWithCourseLessonBookings",
        {
            query: GetBookingByIdWithCourseLessonBookingsDocument,
            variables: {id: bookingId},
        },
        "GetBookingByIdWithCourseLessonBookings",
        {
            query: GetBookingByIdWithCourseLessonBookingsDocument,
            variables: {id: bookingId},
        },
    ];

    const knownErrors: Array<KnownError> = [
        {
            key: 'null value in column "paymentDue" violates not-null constraint',
            title: "Fälligkeitsdatum fehlt",
            msg: "Bitte gib ein Fälligkeitsdatum an um den Posten zu speichern.",
        },
        {
            key: "default",
            title: "Fehler beim Anlegen des neuen Postens",
            msg: "Es ist ein Fehler beim Anlegen des neuen Postens aufgetreten. Bitte versuche es erneut.",
        },
    ];

    const [addPayment] = useAddNamedScheduledPaymentMutation({
        refetchQueries,
        awaitRefetchQueries: true,
    });

    const addNamedPaymentModal = () => {
        return (
            <Formik<AddNamedScheduledPaymentInput>
                initialValues={{
                    bookingId,
                    title: options[0].label,
                    paymentAmount: 10,
                    paymentDue: new Date(),
                    type: options[0].type,
                    sepaClearance: true,
                }}
                validationSchema={AddNamedScheduledPaymentValidation}
                enableReinitialize
                onSubmit={async (values, {setSubmitting, resetForm}) => {
                    setLoading(true);
                    try {
                        await addPayment({
                            variables: {
                                addNamedScheduledPaymentInput: {
                                    bookingId,
                                    title: values.title,
                                    paymentAmount: values.paymentAmount,
                                    paymentDue: values.paymentDue,
                                    type: values.type,
                                    sepaClearance: values.sepaClearance,
                                },
                            },
                        }).then((res) => {
                            if (res.data?.addNamedScheduledPayment) {
                                resetForm();
                                setOpen(false);
                            }
                        });
                        setTimeStamp?.(Date.now());
                    } catch (error) {
                        handleError("addNamedScheduledPayment", error, "modal", knownErrors);
                        console.log("error => ", error);
                    } finally {
                        setLoading(false);
                    }
                }}
                render={(formikProps) => {
                    return (
                        <Modal
                            open={open}
                            onCancel={() => setOpen(false)}
                            width={panelWidth / 1.5}
                            title="Neuen Posten hinzufügen"
                            bodyStyle={{padding: "0"}}
                            okText="Neuen Posten speichern"
                            onOk={() => formikProps.handleSubmit()}
                            okButtonProps={{
                                loading,
                                type: "primary",
                                icon: <SaveOutlined />,
                            }}
                            cancelButtonProps={{
                                disabled: loading,
                            }}
                            destroyOnClose
                        >
                            {loading ? (
                                <LoadingContent />
                            ) : (
                                <AddScheduledPaymetTabs
                                    formikProps={formikProps}
                                    setEditPaymentOpen={setOpen}
                                    bookingId={bookingId}
                                    monthlySchedule={monthlySchedule}
                                />
                            )}
                        </Modal>
                    );
                }}
            />
        );
    };

    return (
        <>
            {addNamedPaymentModal()}
            <Button
                type="default"
                className={css.addButton}
                size="small"
                icon={<PlusCircleOutlined />}
                onClick={() => setOpen(!open)}
            >
                {"Neuen Posten hinzufügen"}
            </Button>
        </>
    );
};

export default memo(AddNamedScheduledPayment);
