import React, {CSSProperties, FC, memo, useState} from "react";
import {ApolloError} from "apollo-client";
import {Alert, Space} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import {red} from "@ant-design/colors";
import Button from "../../../../views/Button/Button";
import {KnownError} from "../../../../errors/handleErrors";

type LessonBookingErrorsProps = {
    error?: Error | ApolloError;
};

export const knownErrors: Array<KnownError> = [
    {
        id: 1,
        key: "Booking already exists",
        title: "Buchung existiert bereits",
        msg: "Eine Buchung mit diesen Daten existiert bereits.",
    },
    {
        id: 2,
        key: "courseId is required",
        title: "Kurs ist erforderlich",
        msg: "Bitte wähle einen Kurs um eine Buchung zu erstellen.",
    },
    {
        id: 3,
        key: "Place reservation is not possible because there are no free slots",
        title: "Platzreservierung ist nicht möglich, weil keine freien Plätze verfügbar sind",
        msg: "Wähle einen Teilnehmer aus, der noch nicht an diesem Kurs teilgenommen hat.",
    },
];

export const getKnownErrorMessage = (error?: Error | ApolloError): string | undefined => {
    if (!error) {
        return undefined;
    }

    if (error instanceof ApolloError) {
        const errorMessage = error.graphQLErrors[0].message || "";
        const knownError = knownErrors.find((known) => errorMessage.includes(known.key));

        return knownError?.msg ?? "Ein Fehler ist aufgetreten";
    }

    return "Ein Fehler ist aufgetreten";
};

const LessonBookingErrors: FC<LessonBookingErrorsProps> = ({error}) => {
    const errorStyle: CSSProperties = {
        padding: "0.2rem 1rem",
        backgroundColor: red[2],
        color: red[7],
        fontWeight: 450,
    };

    if (error instanceof ApolloError) {
        const errorMessage = error.graphQLErrors[0].message || "";
        const knownError = knownErrors.find((known) => errorMessage.includes(known.key));

        const RenderError = ({knownError}: {knownError: KnownError}) => {
            const [showDescription, setShowDescription] = useState(false);

            return (
                <Alert
                    style={errorStyle}
                    message={<div style={{color: errorStyle.color}}>{knownError.title}</div>}
                    description={showDescription ? knownError.msg : null}
                    type="error"
                    showIcon
                    action={
                        <Button
                            type="text"
                            size="small"
                            onClick={() => {
                                setShowDescription(!showDescription);
                            }}
                            style={{color: errorStyle.color, marginRight: "-0.5rem"}}
                        >
                            <Space>
                                <InfoCircleOutlined />
                                <div>{"Mehr Info"}</div>
                            </Space>
                        </Button>
                    }
                />
            );
        };

        if (knownError) {
            return <RenderError knownError={knownError} />;
        }

        // Fallback für unbekannte Fehler
        const RenderFallback = ({error}: {error: Error}) => {
            const [showDescription, setShowDescription] = useState(false);

            return (
                <Alert
                    style={errorStyle}
                    message={<div style={{color: errorStyle.color}}>{"Ein Fehler ist aufgetreten"}</div>}
                    description={showDescription ? error.message : null}
                    type="error"
                    showIcon
                    closable
                    action={
                        <Button
                            type="text"
                            size="small"
                            onClick={() => setShowDescription(!showDescription)}
                            style={{color: errorStyle.color, marginRight: "-0.5rem"}}
                        >
                            <Space>
                                <InfoCircleOutlined />
                                <div>{"Mehr Info"}</div>
                            </Space>
                        </Button>
                    }
                />
            );
        };

        return <RenderFallback error={error} />;
    }

    return null;
};

export default memo(LessonBookingErrors);
