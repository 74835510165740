import {message, Modal, Table, Tooltip} from "antd";
import Maybe from "graphql/tsutils/Maybe";
import React, {FC, useEffect, useState} from "react";
import {ColumnProps} from "antd/lib/table";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {red} from "@ant-design/colors";
import {
    GetAppSettingsQuery,
    PaymentMethod,
    useGetAppSettingsQuery,
    useGetPaymentMethodsByBookerIdQuery,
    useChangeDefaultPaymentMethodMutation,
    GetPaymentMethodsByBookerIdDocument,
    GetBookingByIdDocument,
    GetAttendeeInfosForBookingQuery,
    GetBookingByIdWithCourseLessonBookingsDocument,
} from "../../generated/graphql";
import PaymentMethodBadge from "../PaymentMethodBadge/PaymentMethodBadge";
import css from "./PaymentMethodTable.less";
import {getPaymentMethodType, PaymentMethodData} from "../../helpers/getPaymentMethodType";
import i18n from "../../services/i18n";
import {BookingAttendee} from "../../containers/bookings/CreateEditBookings/BookingCreate";

type PossibleTranslations = {
    sepaDebit: "Lastschrift";
    esr: "Einzahlungsschein";
    creditCard: "Kreditkarte";
    payPal: "PayPal";
    cash: "Bar";
    bankTransfer: "Überweisung";
    none: "Keine";
};

type PaymentMethodTableProps = {
    paymentMethods: Array<Partial<PaymentMethod>>;
    paymentMethodId: Maybe<string>;
    setSelectedPayment: Function;
    showHeader?: boolean;
    bookerId?: string;
    interactive?: boolean;
    attendee?: BookingAttendee | GetAttendeeInfosForBookingQuery["getAttendeeInfosForBooking"];
    bookingId?: string;
};

const PaymentMethodTable: FC<PaymentMethodTableProps> = ({
    paymentMethods,
    paymentMethodId,
    setSelectedPayment,
    showHeader = true,
    bookerId,
    interactive = true,
    attendee,
    bookingId,
}) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string> | Array<number>>([]);

    // GET PAYMENTMETHODS OF COMPANY -----------------------------------------------------------------------------
    type CompanySettings = GetAppSettingsQuery["settings"];
    type CompanyMethods = Pick<CompanySettings, "cash" | "esr" | "bankTransfer" | "sepaDebit">;

    const [companySettings, setCompanySettings] = useState<CompanySettings>();
    const [companyPaymentMethods, setCompanyPaymentMethods] = useState<CompanyMethods>();

    const {data: companySettingsData, loading: loadingSettings} = useGetAppSettingsQuery();

    useEffect(() => {
        if (companySettingsData) {
            setCompanySettings(companySettingsData.settings);

            const companyPamentMethods = {
                cash: companySettingsData.settings.cash,
                esr: companySettingsData.settings.esr,
                bankTransfer: companySettingsData.settings.bankTransfer,
                sepaDebit: companySettingsData.settings.sepaDebit,
            };

            setCompanyPaymentMethods(companyPamentMethods);
        }
    }, [companySettingsData]);

    // GET PAYMENTMETHODS OF BOOKER ------------------------------------------------------------------------------
    const [paymentMethodsOfBooker, setPaymentMethodsOfBooker] = useState<Array<Partial<PaymentMethod>>>(paymentMethods);
    const {data: bookerPaymentMethodsData} = useGetPaymentMethodsByBookerIdQuery({
        skip: !bookerId,
        variables: {bookerId: bookerId ?? ""},
        fetchPolicy: "network-only",
    });

    // console.debug("paymentMethodsOfBooker", paymentMethodsOfBooker);
    const defaultPaymentMethodId = attendee?.defaultPaymentMethod?.id;

    const refetchQueries = () => {
        const queries = [];

        if (bookingId) {
            queries.push({
                query: GetBookingByIdDocument,
                variables: {id: bookingId},
            });
            queries.push({
                query: GetBookingByIdWithCourseLessonBookingsDocument,
                variables: {id: bookingId},
            });
        }

        if (bookerId) {
            queries.push({
                query: GetPaymentMethodsByBookerIdDocument,
                variables: {bookerId},
            });
        }

        return queries;
    };

    const [changeDefaultPaymentMethod] = useChangeDefaultPaymentMethodMutation();

    const handleDefaultPaymentMethodChange = async (
        attendeeId: string,
        paymentMethodId: string,
        selectedPaymentMethod: PaymentMethod,
    ) => {
        try {
            message.loading({
                content: i18n.views.PaymentMethodTable.changingDefaultPaymentMethod(),
                duration: 0,
                key: `${attendeeId}-${paymentMethodId}`,
            });
            await changeDefaultPaymentMethod({
                variables: {
                    attendeeId,
                    paymentMethodId,
                },
                refetchQueries: refetchQueries(),
            }).then((res) => {
                console.log("res", res);

                const newDefaultPaymentMethod = res.data?.changeDefaultPaymentMethod.defaultPaymentMethod;

                if (newDefaultPaymentMethod) {
                    const paymenMethod = getPaymentMethodType(newDefaultPaymentMethod) as keyof PossibleTranslations;

                    const translatedPaymentMethod = i18n.views.PaymentMethodBadge[paymenMethod]();

                    setSelectedRowKeys([paymentMethodId]);
                    setSelectedPayment(selectedPaymentMethod);

                    message.success({
                        content: `${i18n.views.PaymentMethodTable.defaultPaymentMethodChanged()} ${translatedPaymentMethod}`,
                        duration: 3,
                        key: `${attendeeId}-${paymentMethodId}`,
                    });
                }
            });
        } catch (error) {
            console.error("Error changing default payment method", error);
            console.log("error", error);
            message.error({
                content: i18n.views.PaymentMethodTable.errorChangingDefaultPaymentMethod(),
                duration: 5,
                key: `${attendeeId}-${paymentMethodId}`,
            });
        }
    };

    useEffect(() => {
        if (bookerPaymentMethodsData?.getPaymentMethodsByBookerId) {
            const allPaymentMethods = bookerPaymentMethodsData.getPaymentMethodsByBookerId;

            // Make sure that ESR is only selectable if company has ESR
            const selectablePaymentMethods = allPaymentMethods.filter((paymentMethod) => {
                return !paymentMethod.esr || companyPaymentMethods?.esr;
            });

            setPaymentMethodsOfBooker(selectablePaymentMethods);
        }
    }, [bookerPaymentMethodsData, companyPaymentMethods]);

    // ------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (paymentMethodId) {
            console.log("paymentMethodId 1", paymentMethodId);
            setSelectedRowKeys([paymentMethodId]);
        }
    }, [paymentMethodId]);

    const renderBadge = (paymentMethod: PaymentMethodData) => {
        return <PaymentMethodBadge type={getPaymentMethodType(paymentMethod)} />;
    };

    // Hint if is not company method -------------------------------------------------------------------------------
    const isCompanyMethod = (record: PaymentMethodData) => {
        if (!companyPaymentMethods) return null;
        if (record.esr && companyPaymentMethods.esr) return null;
        if (record.bankTransfer && companyPaymentMethods.bankTransfer) return null;
        if (record.cash && companyPaymentMethods.cash) return null;

        if (record.iban !== null && companyPaymentMethods.sepaDebit) return null;

        return (
            <Tooltip title={i18n.views.PaymentMethodTable.notCompanyMethod()}>
                <ExclamationCircleOutlined style={{color: red[3], fontSize: "1.5em"}} />
            </Tooltip>
        );
    };

    // columns -----------------------------------------------------------------------------------------------------
    const columns: Array<ColumnProps<PaymentMethodData>> = [
        {
            title: "",
            dataIndex: "companyPaymentMethod",
            render: (text, record: PaymentMethodData) => {
                return isCompanyMethod(record);
            },
        },
        {
            title: i18n.views.PaymentMethodTable.method(),
            dataIndex: "id",
            render: (text, record: PaymentMethodData) => renderBadge(record),
        },
        {
            title: i18n.views.PaymentMethodTable.sepaAccountholder(),
            dataIndex: "sepaAccountholder",
        },
        {
            title: i18n.views.PaymentMethodTable.iban(),
            dataIndex: "iban",
        },
        {
            title: i18n.views.PaymentMethodTable.bank(),
            dataIndex: "bank",
        },
    ];

    const onSelectChange = (selectedRowKeys: Array<string> | Array<number>, selectedRows: Array<PaymentMethod>) => {
        const selectedPaymentMethod = selectedRows[0];
        const selectedRowKey = selectedRowKeys[0].toString();
        const attendeeId = attendee?.id;

        if (attendeeId && selectedRowKey !== defaultPaymentMethodId) {
            Modal.confirm({
                title: i18n.views.PaymentMethodTable.confirmChangeDefaultPaymentMethodTitle(),
                content: (
                    <>
                        <p>{i18n.views.PaymentMethodTable.confirmChangeDefaultPaymentMethod()}</p>
                        <p>{i18n.views.PaymentMethodTable.confirmChangeDefaultPaymentMethodDescription()}</p>
                    </>
                ),
                icon: null,
                onOk: async () => handleDefaultPaymentMethodChange(attendeeId, selectedRowKey, selectedPaymentMethod),
                style: {textAlign: "center"},
            });
        } else {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedPayment(selectedRows[0]);
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <div className={css.root}>
            <Table
                rowKey="id"
                showHeader={showHeader}
                columns={columns}
                dataSource={paymentMethodsOfBooker}
                pagination={false}
                size="small"
                selectedRowKeys={selectedRowKeys}
                rowSelection={
                    interactive
                        ? {
                              type: "radio",
                              ...rowSelection,
                          }
                        : undefined
                }
                bordered={false}
            />
        </div>
    );
};

export default PaymentMethodTable;
