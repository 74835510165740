import Roles from "../../../shared/models/roles";
import {
    URL_ACCOUNT,
    URL_BADGES,
    URL_BOOKINGS,
    URL_CHANGELOG,
    URL_COURSES,
    URL_COURSES_CATEGORIES,
    URL_COURSES_PREREQUISITE,
    URL_COURSES_TYPES,
    URL_CUSTOMERS,
    URL_CUSTOMERS_DUPLICATES,
    URL_DASHBOARD,
    URL_HOME,
    URL_INSTRUCTOR,
    URL_INVOICE,
    URL_LESSONS_TODAY,
    URL_LOCATIONS,
    URL_MY_BOOKINGS,
    URL_NOTIFICATIONS,
    URL_NOTIFICATIONS_FIXER,
    URL_PAYMENTS,
    URL_SETTINGS,
    URL_SETTINGS_ADMIN,
    URL_SETTINGS_ENDUSERAPP,
    URL_SETTINGS_IMPORT_BOOKERS,
    URL_SETTINGS_MAINTENANCE,
    URL_SETUP,
    URL_STAFF,
    URL_STAFF_LOGIN,
    URL_TEMPLATES,
    URL_WELCOME_PAGE,
} from "../../models/url";

const allWithoutEndUser: Array<Roles> = Object.values(Roles).filter((role) => role !== Roles.EndUser);

export const routePermissions: {[key: string]: Array<Roles>} = {
    [URL_HOME]: allWithoutEndUser,
    // [URL_WELCOME_PAGE]: allWithoutEndUser,
    [URL_WELCOME_PAGE]: [],
    [URL_SETUP]: allWithoutEndUser,
    [URL_COURSES]: allWithoutEndUser,
    [URL_COURSES_PREREQUISITE]: [Roles.Superuser, Roles.Admin, Roles.BetaTester],
    [URL_COURSES_TYPES]: [Roles.Superuser, Roles.Admin, Roles.Staff, Roles.BetaTester],
    [URL_COURSES_CATEGORIES]: [Roles.Superuser, Roles.Admin, Roles.Staff, Roles.BetaTester],
    [URL_CUSTOMERS]: allWithoutEndUser,
    [URL_CUSTOMERS_DUPLICATES]: [Roles.Superuser],
    [URL_LOCATIONS]: [Roles.Superuser, Roles.Admin, Roles.Staff, Roles.BetaTester],
    [URL_BOOKINGS]: [Roles.Superuser, Roles.Admin, Roles.BetaTester, Roles.SuperAdmin],
    [URL_MY_BOOKINGS]: allWithoutEndUser,
    [URL_INVOICE]: [Roles.Superuser, Roles.Admin, Roles.Staff, Roles.BetaTester, Roles.SuperAdmin],
    [URL_TEMPLATES]: [Roles.Superuser, Roles.Admin, Roles.BetaTester, Roles.SuperAdmin],
    [URL_PAYMENTS]: [Roles.Superuser, Roles.Admin, Roles.BetaTester, Roles.SuperAdmin],
    [URL_STAFF]: allWithoutEndUser,
    [URL_STAFF_LOGIN]: allWithoutEndUser,
    [URL_SETTINGS]: [Roles.Superuser, Roles.Admin, Roles.BetaTester, Roles.SuperAdmin],
    [URL_SETTINGS_ENDUSERAPP]: [Roles.Superuser],
    [URL_SETTINGS_ADMIN]: [Roles.Superuser],
    [URL_SETTINGS_IMPORT_BOOKERS]: [Roles.Superuser],
    [URL_SETTINGS_MAINTENANCE]: [Roles.Superuser],
    [URL_NOTIFICATIONS]: [Roles.Superuser, Roles.Admin, Roles.BetaTester, Roles.SuperAdmin],
    [URL_NOTIFICATIONS_FIXER]: [Roles.Superuser],
    [URL_CHANGELOG]: allWithoutEndUser,
    [URL_BADGES]: [Roles.Superuser, Roles.Admin, Roles.BetaTester, Roles.SuperAdmin],
    [URL_ACCOUNT]: allWithoutEndUser,
    [URL_MY_BOOKINGS]: [Roles.Instructor, Roles.Admin, Roles.Superuser, Roles.BetaTester, Roles.SuperAdmin],
    [URL_INSTRUCTOR]: [Roles.Instructor, Roles.Admin, Roles.Superuser, Roles.BetaTester, Roles.SuperAdmin],
    // [URL_LESSONS_TODAY]: [Roles.Superuser, Roles.BetaTester],
    [URL_LESSONS_TODAY]: allWithoutEndUser,
    [URL_DASHBOARD]: [Roles.Superuser, Roles.BetaTester, Roles.Admin, Roles.SuperAdmin],
};
